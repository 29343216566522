import { ServiceData } from "../../components/ServiceCard";

const services: ServiceData[] = [
    {
        title: "Oznaczenie obiektów uyteczności publicznej",
        description:
            "Produkujemy tabliczki na drzwi, tablice informacyjne, strzałki kierunkowe i wszystko co trzeba żeby sprawnie kierować ruchem użytkowników biur, hal i innych obiektów.",
        imageCode: "1",
    },
    {
        title: "Witryny sklepowe",
        description:
            "Druk i montaż grafik na witrynach sklepów i innych lokali.",
        imageCode: "2",
    },
    {
        title: "Rollupy, systemy wystawiennicze",
        description:
            "Przenośne materiały reklamowe takie jak rollupy sprawdzają się na targach, konferencjach i wielu innych imprezach.",
        imageCode: "3",
    },
    {
        title: "Litery, grafiki przestrzenne ze styroduru",
        description:
            "Styrodur w produkcji reklamy przestrzennej stosujemy głównie przy dekoracjach wnętrz i oznakowaniu budynków. Wykorzystaliśmy go również przy nietypowych wyzwaniach, takich jak makieta pieca do muzeum lub replika bimy w synagodze we Włodawie.",
        imageCode: "4",
    },
    {
        title: "Szyldy, banery, tablice reklamowe",
        description:
            "Tradycyjne reklamy na tablicach wieszane na budynkach lub wolnostojące. Szyldy przestrzenne z aludibondu są tańszą ale bardzo efektowną alternatywą dla kasetonów świecących.",
        imageCode: "5",
    },
    {
        title: "Litery 3D",
        description:
            "Litery przestrzenne po podświetleniu diodami są ekskluzywną formą reklamy. Technologia produkcji pozwala tworzyć je w dowolnych kształtach i rozmiarach.",
        imageCode: "6",
    },
    {
        title: "Kasetony podświetlane, pylony",
        description:
            "Podświetlane szyldy zdobią wiele sklepów, punktów usługowych i firm. Wykonujemy je z nowoczesnych, odpornych na warunki atmosferyczne materiałów oraz wysokiej jakości podzespołów.",
        imageCode: "7",
    },
    {
        title: "Reklamy na pojazdach",
        description:
            "Grafiki na wszelkich pojazdach, od samochodów osobowych, busów, po ciężarówki i autobusy. Stosujemy grafiki ploterowe lub wydruki cyfrowe. Jeśli reklamy mają być łatwo demontowalne wykonujemy je na folii magnetycznej.",
        imageCode: "8",
    },
];

const getVisualData = () => services;

export default getVisualData;

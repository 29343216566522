import * as React from "react";
import Layout from "../components/Layout";
import ServicesPanel from "../components/ServicesPanel";
import useColor from "../context/ColorContext";
import getVisualData from "../utils/functions/getVisualData";

const VisualPage = () => {
    const { setColor } = useColor();
    const services = getVisualData();

    setColor?.("red");
    return (
        <>
            <Layout>
                <ServicesPanel
                    description="Szyldy, litery przestrzenne i kasetony oraz inne kreatywne rozwiązania wizualne."
                    heading={"Reklama wizualna"}
                    services={services}
                    category={"reklamawizualna"}
                />
            </Layout>
        </>
    );
};

export default VisualPage;
